import React from 'react'
import PropTypes from 'prop-types'
//import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Footer from '../components/Footer'
import Ccbyncnd from '../components/Ccbyncnd'
import Content, { HTMLContent } from '../components/Content'
import Img from 'gatsby-image'
import Url from '../components/Url'

export const StoriesPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  featuredimage,
  image_alt,
  attribution_alt,
  attribution_text,
  attribution_link,
  location_alt,
  location_text,
  location_link,
  slug,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <p>{description}</p>
              <Img
                fluid={featuredimage.childImageSharp.fluid}
                alt={image_alt}
              />
              <div className="column is-12 has-text-right pr-6 is-size-7 has-text-grey">
                Photo by{' '}
                <Url
                  url={attribution_link}
                  alt={attribution_alt}
                  text={attribution_text}
                />{' '}
                on{' '}
                <Url
                  url={location_link}
                  alt={location_alt}
                  text={location_text}
                />
              </div>
              <div className="block">
                <PostContent content={content} />
                <Link to="/stories/" className="button has-text-link">Back to Stories</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

StoriesPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image_alt: PropTypes.string,
  attribution_alt: PropTypes.string,
  attribution_text: PropTypes.string,
  attribution_link: PropTypes.string,
  location_alt: PropTypes.string,
  location_text: PropTypes.string,
  location_link: PropTypes.string,
  slug: PropTypes.string,
}

const StoriesPost = ({ data }) => {
  const { mdx: post } = data

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.featuredimage}
        paymentpointer={post.frontmatter.paymentpointer}
      />
      <StoriesPostTemplate
        content={post.body}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Story">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        image_alt={post.frontmatter.image_alt}
        attribution_alt={post.frontmatter.attribution_alt}
        attribution_text={post.frontmatter.attribution_text}
        attribution_link={post.frontmatter.attribution_link}
        location_alt={post.frontmatter.location_alt}
        location_text={post.frontmatter.location_text}
        location_link={post.frontmatter.location_link}
        slug={post.slug}
      />
      <Footer>
        <Ccbyncnd />
      </Footer>
    </Layout>
  )
}

StoriesPost.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object,
  }),
}

export default StoriesPost

export const pageQuery = graphql`
  query StoriesPostByIDCP($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      slug
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        paymentpointer
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image_alt
        attribution_alt
        attribution_text
        attribution_link
        location_alt
        location_text
        location_link
      }
    }
  }
`
